import { React, useState, useEffect } from "react";
import SliderImages from "../meniu/MeniuItems.js";
import ArrowLeft from "../../assets/ArrowLeft.svg";
import ArrowRight from "../../assets/ArrowRight.svg";
import { v4 as uuidv4 } from "uuid";
import "./slider.scss";

const Slider = ({ getZoomImage }) => {
  const [currentItems, setCurrentItems] = useState([0, 1, 2]);
  const [currentActiveDot, setCurrentActiveDot] = useState(0);
  const [zoomImage, setZoomImage] = useState();

  const nextImagesHandler = () => {
    setTimeout(() => {
      let newList = [];
      for (let i = 0; i < currentItems.length; i++) {
        if (currentItems[i] < 8) {
          let numbers = currentItems[i] + 3;
          newList.push(numbers);
          setCurrentActiveDot(currentActiveDot + 1);
        }
        if (currentItems[i] === 10) {
          return null;
        }
      }

      setCurrentItems(newList);
    }, 100);
  };

  const previousImagesHandler = () => {
    setTimeout(() => {
      let newList = [];
      if (currentItems.length !== 2) {
        for (let i = 0; i < currentItems.length; i++) {
          if (currentItems[i] > 2 && currentItems[i] < 10) {
            newList.push(currentItems[i] - 3);
            setCurrentItems(newList);
            setCurrentActiveDot(currentActiveDot - 1);
          }
        }
      } else {
        setCurrentItems([6, 7, 8]);
        setCurrentActiveDot(currentActiveDot - 1);
      }
    }, 100);
  };

  const dotNavigationHandler = (e) => {
    let buttons = document.querySelectorAll(".nav-dot-button");
    for (let i = 0; i < dotNavigationHandler.length; i++) {
      if (e.target === buttons[0]) {
        setCurrentItems([0, 1, 2]);
        setCurrentActiveDot(0);
      }
      if (e.target === buttons[1]) {
        setCurrentItems([3, 4, 5]);
        setCurrentActiveDot(1);
      }
      if (e.target === buttons[2]) {
        setCurrentItems([6, 7, 8]);
        setCurrentActiveDot(2);
      }
      if (e.target === buttons[3]) {
        setCurrentItems([9, 10]);
        setCurrentActiveDot(3);
      }
    }
  };

  const zoomImageHandler = (event) => {
    setZoomImage(event.target.src);
    setTimeout(() => {
      const zoomOverlayContainer = document.querySelector(
        ".zoom-overlay-container"
      );
      if (zoomOverlayContainer.classList.contains("hidden")) {
        zoomOverlayContainer.classList.toggle("hidden");
      }
    }, 10);
  };

  useEffect(() => {
    getZoomImage(zoomImage);
  }, [zoomImage]);

  return (
    <div className="slider-container" id="slider">
      <div className="images-container">
        {currentItems.map((index) => {
          if (currentItems.includes(10)) {
            return (
              <div key={uuidv4()} className="img-container">
                <img
                  key={uuidv4()}
                  src={SliderImages[index].img}
                  alt="Menu Picture"
                  onClick={zoomImageHandler}
                  className="slider-image"
                />
              </div>
            );
          } else {
            return (
              <div key={uuidv4()} className="img-container">
                <img
                  key={uuidv4()}
                  src={SliderImages[index].img}
                  alt="Menu Picture"
                  onClick={zoomImageHandler}
                  className="slider-image"
                />
              </div>
            );
          }
        })}
      </div>
      <div className="slide-navigation">
        <button className="prev-button" onClick={previousImagesHandler}>
          <a href="#slider">
            <img src={ArrowLeft} alt="Previous Images" />
          </a>
        </button>
        <div className="dots-nav">
          <a href="#slider">
            <button
              className={
                currentActiveDot === 0
                  ? "nav-dot-button active"
                  : "nav-dot-button"
              }
              onClick={dotNavigationHandler}
            ></button>
          </a>
          <a href="#slider">
            <button
              className={
                currentActiveDot === 1
                  ? "nav-dot-button active"
                  : "nav-dot-button"
              }
              onClick={dotNavigationHandler}
            ></button>
          </a>
          <a href="#slider">
            <button
              className={
                currentActiveDot === 2
                  ? "nav-dot-button active"
                  : "nav-dot-button"
              }
              onClick={dotNavigationHandler}
            ></button>
          </a>
          <a href="#slider">
            <button
              className={
                currentActiveDot === 3
                  ? "nav-dot-button active"
                  : "nav-dot-button"
              }
              onClick={dotNavigationHandler}
            ></button>
          </a>
        </div>
        <button className="prev-button" onClick={nextImagesHandler}>
          <a href="#slider">
            <img src={ArrowRight} alt="Next Images" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Slider;
