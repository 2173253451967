import { React, useState } from "react";
import "./meniu.scss";
import downloadPDF from "../../functions/downloadPDF";
import images from "./MeniuItems.js";
import Slider from "../slider/Slider";
import zoomOverlayHandler from "../../functions/zoomOverlayHandler.js";
const Meniu = () => {
  const [zoomImage, setZoomImage] = useState(null);
  const getZoomImage = (image) => {
    setZoomImage(image);
  };
  return (
    <section id="meniu">
      {zoomImage && (
        <div className="zoom-overlay-container" onClick={zoomOverlayHandler}>
          <div className="zoom-overlay-image-container">
            <img src={zoomImage} alt="Imagine Meniu" />
          </div>
        </div>
      )}

      <div className="menu-text">
        <p>Alege din meniul nostru divers!</p>
      </div>
      <div className="descarca-pdf">
        <button onClick={downloadPDF}>Descarcă PDF!</button>
      </div>
      <div className="meniu-container">
        <Slider getZoomImage={getZoomImage} />
      </div>
    </section>
  );
};

export default Meniu;
