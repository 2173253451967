const zoomOverlayHandler = () => {
  const zoomOverlayContainer = document.querySelector(
    ".zoom-overlay-container"
  );

  if (!zoomOverlayContainer.classList.contains("hidden")) {
    zoomOverlayContainer.classList.toggle("hidden");
  }
};

export default zoomOverlayHandler;
