import React from "react";
import Aer from "../../assets/servicii/Aer.png";
import TakeAway from "../../assets/servicii/Take.png";
import Wifi from "../../assets/servicii/Wifi.png";
import Terasa from "../../assets/servicii/Terasa.png";
import Deliver from "../../assets/servicii/Deliver.png";
import "./servicii.scss";
const Servicii = () => {
  return (
    <section id="servicii" className="servicii">
      <h2>Servicii Oferite</h2>
      <div className="servicii-container">
        <div className="service">
          <div className="service-img-container">
            <img src={Wifi} alt="Wifi Gratuit" />
          </div>
          <p>Wi-fi Gratuit</p>
        </div>
        <div className="service">
          <div className="service-img-container">
            <img src={Deliver} alt="Livrare Domiciliu" />
          </div>
          <p>Livrare la domiciliu</p>
        </div>
        <div className="service">
          <div className="service-img-container">
            <img src={Terasa} alt="Terasa" />
          </div>
          <p>Terasă</p>
        </div>
        <div className="service">
          <div className="service-img-container">
            <img src={Aer} alt="Aer conditionat" />
          </div>
          <p>Aer condiționat</p>
        </div>
        <div className="service">
          <div className="service-img-container">
            <img src={TakeAway} alt="Ridicare Personala" />
          </div>
          <p>Ridicare Personală</p>
        </div>
      </div>
    </section>
  );
};

export default Servicii;
