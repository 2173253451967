import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import HamburgerMenu from "../../assets/HamburgerMenu.svg";
import CloseMenuImg from "../../assets/closeMenu.svg";
import "./navbar.scss";
import manageNavState from "../../functions/manageNavState";
import manageNav from "../../functions/manageNav";

const Navbar = () => {
  return (
    <>
      <button className="hamburger" onClick={manageNavState}>
        <img src={HamburgerMenu} alt="Menu" className="hamburger-img" />
      </button>
      <nav className="navbar">
        <div className="logo-container">
          <button className="closeMenu" onClick={manageNavState}>
            <img src={CloseMenuImg} alt="Close Menu" />
          </button>
          <a href="/" className="logo-link">
            <Logo className="logo" />
          </a>
          <hr className="logo-horizontal-line" />
        </div>

        <div className="nav-links">
          <a href="#acasa" className="nav-link" onClick={manageNav}>
            Acasă
          </a>
          <a href="#meniu" className="nav-link" onClick={manageNav}>
            Meniu
          </a>
          <a href="#servicii" className="nav-link" onClick={manageNav}>
            Servicii
          </a>
        </div>

        <div className="comanda-acum-container">
          <p>Comandă Acum!</p>
          <a href="tel:+40-784-996-130">+40 784 996 130</a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
