const manageNav = () => {
  const navbar = document.querySelector(".navbar");

  if (navbar.classList.contains("show")) {
    navbar.classList.toggle("show");
  } else {
    return null;
  }
};
export default manageNav;
