import React from "react";
import Logo from "../../assets/logo.svg";
import Oredeschidere from "./Oredeschidere";
import AcasaText from "./AcasaText";
import ImaginiJohn from "./ImaginiJohn";
import "./acasa.scss";

const Acasa = () => {
  return (
    <section id="acasa" className="acasa">
      <div className="acasa-logo">
        <img src={Logo} alt="logo" />
      </div>
      <AcasaText />
      <Oredeschidere />
      <ImaginiJohn />
    </section>
  );
};

export default Acasa;
