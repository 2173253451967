import React from "react";
import Logo from "../../assets/logo.svg";
import FacebookImg from "../../assets/Facebook.svg";
import InstagramImg from "../../assets/Instagram.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-socials">
        <a href="/" className="footer-logo">
          <img src={Logo} alt="Logo" />
        </a>
        <div className="follow-us">
          <p>Urmăriți-ne pe Social Media</p>
        </div>
        <div className="socials">
          <div className="social-item-container">
            <a
              href="https://www.facebook.com/pizzeriajohn/?ref=page_internal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={FacebookImg} alt="Facebook Social" />
            </a>
          </div>
          <div className="social-item-container">
            <a
              href="https://www.instagram.com/pizzeria.john/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={InstagramImg} alt="Instagram Social" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-contact">
        <h3>Contact</h3>

        <a href="mailto: pizzeriajohn@gmail.com" className="e-mail-contact">
          pizzeriajohn@gmail.com
        </a>
        <a href="tel:+40-784-996-130" className="phone-contact">
          +40 784 996 130
        </a>
        <div className="visit-us">
          <a
            href="https://www.google.com/maps/dir//Pizza+John,+DJ687,+Pe%C8%99ti%C8%99u+Mare+331004,+Romania/@45.803843,22.9219695,17z/data=!4m16!1m6!3m5!1s0x474e8bfb802e4027:0x20b52b5b7021f8ea!2sPizza+John!8m2!3d45.8038393!4d22.9241582!4m8!1m0!1m5!1m1!1s0x474e8bfb802e4027:0x20b52b5b7021f8ea!2m2!1d22.9241582!2d45.8038393!3e3"
            target="_blank"
            rel="noreferrer"
          >
            <p className="find-us">
              Strada Principală Nr. 38C, Peștișu-Mare, Hunedoara
            </p>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
