import React from "react";
import "./stickyorder.scss";

const StickyOrder = () => {
  return (
    <div className="sticky-order">
      <p>Comandă Acum!</p>
      <a href="tel:+40-784-996-130">+40 784 996 130</a>
    </div>
  );
};

export default StickyOrder;
