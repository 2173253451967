import React from "react";
import "./oredeschidere.scss";

const Oredeschidere = () => {
  return (
    <div className="ore-deschidere-info">
      <div className="ore-deschidere">
        <div className="left-line"></div>
        <p>Luni-Sâmbătă</p>
        <p>10:00-22:00</p>
        <div className="right-line"></div>
      </div>
      <div className="ore-deschidere">
        <div className="left-line"></div>
        <p>Duminică</p>
        <p>11:00-22:00</p>
        <div className="right-line"></div>
      </div>
    </div>
  );
};

export default Oredeschidere;
