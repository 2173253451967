import React from "react";
import Img from "../assets/img.png";
import Navbar from "../components/navbar/Navbar";
import Acasa from "../components/acasa/Acasa";
import Meniu from "../components/meniu/Meniu";
import Footer from "../components/footer/Footer";
import Servicii from "../components/servicii/Servicii";
import StickyOrder from "../components/stickyorder/StickyOrder";
import "./home.scss";

const Home = () => {
  return (
    <div className="home">
      <StickyOrder />
      <img src={Img} className="bg-img" />
      <header>
        <Navbar />
      </header>
      <div className="divide">
        <main>
          <Acasa />
          <Meniu />
          <Servicii />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
