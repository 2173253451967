import React from "react";
import "./acasatext.scss";

const AcasaText = () => {
  return (
    <div className="acasa-text-container">
      <div className="slogan">
        <h1>Bun de fiecare dată!</h1>
      </div>
      <div className="poveste">
        <p>
          Noi punem calitatea produselor pe primul loc, cu o gamă variată de
          preparate, suntem siguri ca vom fi pe gustul tau! Livrăm la domiciliu
          in Hunedoara şi satele învecinate! Pizza John, Bun de fiecare
          data!
        </p>
      </div>
    </div>
  );
};

export default AcasaText;
