import React from "react";
import "./imaginiJohn.scss";
import Bruschetta from "../../assets/Bruschetta.jpeg";
import Carne from "../../assets/Carne.jpeg";
import Paste from "../../assets/Paste.jpeg";
import Crispy from "../../assets/Crispy.jpeg";
import Pizza from "../../assets/Pizza.jpeg";
import { v4 as uuidv4 } from "uuid";

const ImaginiJohn = () => {
  const images = [Bruschetta, Pizza, Paste, Crispy, Carne];
  return (
    <div className="imagini-john-container">
      {images.map((imagine) => {
        return <img key={uuidv4()} src={imagine} alt="Imagine Mancare" />;
      })}
    </div>
  );
};

export default ImaginiJohn;
