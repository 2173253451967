import Aperitive from "../../assets/Poze/Aperitive.jpeg";
import Carne from "../../assets/Poze/Carne.jpeg";
import Desert from "../../assets/Poze/Desert.jpeg";
import Important from "../../assets/Poze/Important.jpeg";
import Meniuri from "../../assets/Poze/Meniuri.jpeg";
import Paste from "../../assets/Poze/Paste.jpeg";
import Burgeri from "../../assets/Poze/Burgeri.jpeg"
import Pizza from "../../assets/Poze/Pizza.jpeg";
import Pizza2 from "../../assets/Poze/Pizza2.jpeg"
import Racoritoare from "../../assets/Poze/Racoritoare.jpeg";
import Vinuri from "../../assets/Poze/Vinuri.jpeg";
import { v4 as uuidv4 } from "uuid";
const images = [
  {
    id: uuidv4(),
    img: Aperitive,
  },
  {
    id: uuidv4(),
    img: Pizza,
  },
  {
    id: uuidv4(),
    img: Pizza2,
  },
  {
    id: uuidv4(),
    img: Paste,
  },
  {
    id: uuidv4(),
    img: Carne,
  },
  {
    id: uuidv4(),
    img: Burgeri,
  },
  {
    id: uuidv4(),
    img: Meniuri,
  },
  {
    id: uuidv4(),
    img: Desert,
  },
  {
    id: uuidv4(),
    img: Racoritoare,
  },
  {
    id: uuidv4(),
    img: Vinuri,
  },
  {
    id: uuidv4(),
    img: Important,
  },
];
export default images;
